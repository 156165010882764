import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import backgrounds from '../../assets/img/backgrounds'

import textPT from '../../assets/data/translate/hero/text-pt-black-friday.json'
import * as S from './style'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import PositiveImage from './../../assets/img/svg/sinal-positivo.svg'

const HeroBlackFriday2024 = () => {
  const { title, description, textPromotion, textCashback, CTA }: ISlideScrollSections = textPT
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <S.ModelsSectionBackground
      key='TESTE'
      mobileBackgroundImage={backgrounds.backgroundHomeSection1.mobile}
      backgroundImageLG={backgrounds.backgroundHomeSection1.desktopLG}
      backgroundImageXL={backgrounds.backgroundHomeSection1.desktopXL}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-6 col-xl-8 px-0'>
            <S.TitleExtraLarge
              as='h1'
              className='font-citrina'
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <S.Description dangerouslySetInnerHTML={{ __html: description }} />
            <S.Promotion>
              <img
                src={PositiveImage} alt='Sinal de positivo'
                width={width <= WIDTH_MD ? 30 : 50}
                height={width <= WIDTH_MD ? 30 : 50}
              />
              <div>
                <div dangerouslySetInnerHTML={{ __html: textPromotion }} />
                <div dangerouslySetInnerHTML={{ __html: textCashback }} />
              </div>
            </S.Promotion>
            <div>
              <S.CTA
                title={CTA.text}
                to={CTA.link}
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Orange Friday - Comprar muito e pagar pouco',
                    element_action: 'click button',
                    element_name: CTA.text,
                    redirect_url: CTA.link,
                  })
                }}
              >
                {CTA.text}
                <ArrowRight height={24} width={24} color='#FFFF' className='ml-2' />
              </S.CTA>
            </div>
          </div>
        </div>
      </div>
    </S.ModelsSectionBackground>
  )
}

export default HeroBlackFriday2024
