import { Link } from 'gatsby'
import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

import { orange, white } from 'src/styles/colors'
import { IModelsSectionBackground } from '../../types'

export const ModelsSectionBackground = styled('div')<IModelsSectionBackground>`
  height: 100vh;
  background: ${(props: IModelsSectionBackground) => `#161616 url(${props.mobileBackgroundImage})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  padding-top: 35px;
  display: flex;

  @media ${device.tablet} {
    background: ${(props: IModelsSectionBackground) => `#161616 url(${props.backgroundImageLG})`};
    background-position: bottom center;
    background-size: 670px;
    background-repeat: no-repeat;
  }
  @media ${device.desktopLG} {
    padding-top: 0;
    background: ${(props: IModelsSectionBackground) => `#161616 url(${props.backgroundImageLG})`};
    background-position: bottom right;
    background-size: 600px;
    background-repeat: no-repeat;
    align-items: center;
  }
  @media ${device.desktopXL} {
    background: ${(props: IModelsSectionBackground) => `#161616 url(${props.backgroundImageXL})`};
    background-position: bottom right 90px;
    background-size: 500px;
    background-repeat: no-repeat;
  }
  @media ${device.desktopXXXL} {
    background-size: 610px;
  }
`

export const TitleExtraLarge = styled.h1`
  font-size: 30px;
  line-height: 36px;
  color: ${white};
  font-weight: 600;

  @media ${device.tablet} {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 15px;
  }
  @media ${device.desktopXL} {
    font-size: 72px;
    line-height: 80px;
    margin-top: 40px;
  }
  @media ${device.desktopXXXL} {
    font-size: 80px;
    line-height: 88px;
  }
`

export const Description = styled.p`
  font-size: 18px;
  line-height: 22px;
  color: ${white};
  margin-bottom: 20px;

  @media ${device.tablet} {
    font-size: 24px;
    line-height: 32px;
  }
  @media ${device.desktopLG} {
    font-size: 32px;
    line-height: 38px;
  }
  @media ${device.desktopXL} {
    font-size: 48px;
    line-height: 58px;
    max-width: 700px;
  }
  @media ${device.desktopXXLL} {
    font-size: 48px;
    line-height: 58px;
    max-width: 560px;
  }

  span {
    color: #FFCA96;

    @media ${device.desktopLG} {
    }
  }
`

export const Promotion = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  margin-top: 15px;
  margin-bottom: 20px;

  @media ${device.tablet} {
    margin-top: 32px;
  }

  div {
    color: ${white};

    span {
      font-size: 38px;
      line-height: 42px;
      color: ${orange[500]};
      font-weight: bold;
      font-family: 'Citrina', Helvetica, sans-serif;

      @media ${device.desktopLG} {
        font-size: 72px;
        line-height: 80px;
      }
      @media ${device.desktopXL} {
        font-size: 80px;
        line-height: 88px;
      }
    }
    strong {
      font-size: 38px;
      line-height: 42px;
      color: ${white};
      font-weight: bold;
      font-family: 'Citrina', Helvetica, sans-serif;

      @media ${device.desktopLG} {
        font-size: 72px;
        line-height: 80px;
      }
      @media ${device.desktopXL} {
        font-size: 80px;
        line-height: 88px;
      }
  
      &.cashback {
        font-size: 32px;
        line-height: 35px;

        @media ${device.desktopLG} {
          font-size: 48px;
          line-height: 52px;
        }
      }
    }
  }

  img {
    margin-right: 20px;
  }
`

export const CTA = styled(Link)`
  font-size: 14px;
  line-height: 17px;
  color: ${white};
  display: block;
  float: left;

  &:hover {
    color: ${white};
    opacity: 0.9;
  }
  @media ${device.desktopLG} {
    margin-top: 20px;
    margin-bottom: 80px;
    font-size: 18px;
    line-height: 22px;
  }
  @media ${device.desktopXL} {
    margin-top: 10px;
    margin-bottom: 0;
  }
  @media ${device.desktopXXXL} {
    font-size: 32px;
    line-height: 38px;
  }
`
