import React, { useEffect } from 'react'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import SlideScrollSections from 'src/components/SlideScrollSections'
import { initTheme, Theme } from '@interco/inter-ui'

const Home = () => {
  useEffect(() => {
    initTheme(Theme.PF)
  })

  return (
    <Layout pageContext={pageContext}>
      <SlideScrollSections />
    </Layout>
  )
}

export default Home
