import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import useWidth from 'src/hooks/window/useWidth'
import { grayscale, white } from 'src/styles/colors'
import { WIDTH_MD } from 'src/utils/breakpoints'
import backgrounds from '../../assets/img/backgrounds'

import * as S from './style'

import textPT from '../../assets/data/translate/busca-que-o-inter-tem/text-pt.json'

const BuscaQueOInterTem = React.forwardRef((props: {}, ref: React.Ref<HTMLDivElement>) => {
  const width = useWidth()
  const { title, description, CTA }: ISlideScrollSections = textPT
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.ModelsSectionBackground
      key='TESTE 6'
      className='d-flex align-items-end align-items-md-center'
      mobileBackgroundImage={backgrounds.backgroundHomeSection6.mobile}
      backgroundImage={backgrounds.backgroundHomeSection6.desktop}
      ref={ref}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 offset-md-6'>
            <div className='ml-md-5 pl-md-5'>
              <S.TitleExtraLarge as='h2' className='fw-400 f-sora text-white' dangerouslySetInnerHTML={{ __html: title }} />
              <p
                className='fs-20 lh-25 fs-lg-24 lh-lg-30 fs-xl-32 lh-xl-40 f-sora fw-400 text-white my-3'
                dangerouslySetInnerHTML={{ __html: description }}
              />
              <div>
                <S.CTA
                  href={CTA.link}
                  className={`${width < WIDTH_MD ? 'text-white' : 'text-grayscale--500'}`}
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_06',
                      section_name: 'Busca que o Inter tem',
                      element_action: 'click button',
                      element_name: CTA.text,
                      redirect_url: CTA.link,
                    })
                  }}
                >
                  {CTA.text}
                  <ArrowRight height={24} width={24} color={width < WIDTH_MD ? white : grayscale[500]} className='ml-2' />
                </S.CTA>
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.ModelsSectionBackground>
  )
})

export default BuscaQueOInterTem
