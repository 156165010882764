import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import backgrounds from '../../assets/img/backgrounds'
import * as S from './style'

import textPT from '../../assets/data/translate/a-sua-vida-financeira/text-pt.json'

const ASuaVidaFinanceiraMaisSegura = React.forwardRef((props: { setIsOpen: Function }, ref: React.Ref<HTMLDivElement>) => {
  const { title, CTA = { text: '', link: '' } }: ISlideScrollSections = textPT
  const { setIsOpen }: { setIsOpen: Function } = props
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.ModelsSectionBackground
      key='TESTE 2'
      mobileBackgroundImage={backgrounds.backgroundHomeSection2.mobile}
      backgroundImage={backgrounds.backgroundHomeSection2.desktop}
      ref={ref}
    >
      <div className='container-fluid' style={{ paddingTop: 140 }}>
        <div className='row'>
          <div className='col-12 px-md-0'>
            <div>
              <S.TitleExtraLarge
                as='h2' className='fw-400 f-sora text-white text-center'
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <div className='text-center'>
                <S.CTA
                  as='button'
                  className='text-white text-center'
                  aria-label={CTA.text}
                  onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_02',
                      section_name: 'Segurança pra tudo',
                      element_action: 'click button',
                      element_name: CTA.text,
                    })
                  }}
                >
                  {CTA.text}
                  <ArrowRight height={24} width={24} color='#FFFF' className='ml-2' />
                </S.CTA>
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.ModelsSectionBackground>
  )
})

export default ASuaVidaFinanceiraMaisSegura
