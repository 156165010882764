import { Link } from 'gatsby'
import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

import { grayscale } from 'src/styles/colors'
import { IModelsSectionBackground } from '../../types'

export const Container = styled.div``

export const ModelsSectionBackground = styled('div')<IModelsSectionBackground>`
  height: 100vh;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  position: relative;
`

export const Spacer = styled.div`
  height: 15vh;
  background: ${grayscale[500]};
`

export const TitleLarge = styled.h1`
  font-size: 36px;
  line-height: 45px;

  @media ${device.tablet} {
    font-size: 36px;
    line-height: 45px;
  }
  @media ${device.desktopLG} {
    font-size: 48px;
    line-height: 60px;
  }
  @media ${device.desktopXL} {
    font-size: 72px;
    line-height: 91px;
  }
`

export const TextContainer = styled.div`
  margin-bottom: 160px;

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`

export const TitleExtraLarge = styled.h1`
  font-size: 40px;
  line-height: 44px;

  @media ${device.tablet} {
    font-size: 40px;
    line-height: 44px;
  }
  @media ${device.desktopLG} {
    font-size: 56px;
    line-height: 60px;
  }
  @media ${device.desktopXL} {
    font-size: 80px;
    line-height: 88px;
  }

  @media ${device.desktopXXXL} {
    font-size: 104px;
    line-height: 114px;
  }
`

export const CTA = styled(Link)`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;

  &:focus {
    border: 2px solid;
  }
  &:hover {
    color: ${grayscale[500]};
    opacity: 0.7;
  }
  @media ${device.desktopLG} {
    font-size: 24px;
    line-height: 30px;
  }
  @media ${device.desktopXXXL} {
    font-size: 32px;
    line-height: 38px;
  }
`

export const ContainerVideo = styled.div`
  position: relative;

`

export const AbsoluteBlockText = styled.div`
  position: absolute;
  top: 50%;
  left: 41%;
  transform: translate(-50%, -50%);
`

export const VideoBlock = styled.video`
`
